import React from "react";

const Sobre = () => {
  return (
    <div className="row mt-5 pt-5">
      <div className="col-md-12 sobre">
        <div className="rotolo">
          <p>
            A IEA (International Energy Agency) rotulou a eficiência energética
            como o “First Fuel”, ou seja, o combustível que não temos de
            utilizar para satisfazermos as nossas necessidades.
          </p>
        </div>
        <div className="objetivo">
          <p className="title">Objectivo</p>
          <p>
            A medida agora proposta, intitulada de “FirstFuel”, tem como
            objetivo a remoção de barreiras de mercado tendo em vista a promoção
            da eficiência energética a clientes do setor de “comércio e
            serviços” através da disponibilização de uma solução de
            aconselhamento energético assente em conceitos de Inteligência
            Artificial e “machine learning” e no acesso aos dados de consumos de
            energia disponibilizados pelos contadores de energia instalados pelo
            Operador da Rede de Distribuição em Baixa Tensão (ORDBT).
          </p>
          <p>
            A solução proposta para a execução desta medida para promoção de
            eficiência energética é disruptiva e altamente escalável, face às
            soluções de monitorização que existem no mercado devido a 3
            características principais:
          </p>
          <ul>
            <li>
              O{" "}
              <span className="text-semibold">
                acesso aos dados de consumos de energia
              </span>{" "}
              será garantido através de uma camada de monitorização que já se
              encontra instalada, ou seja, através dos contadores de energia
              instalados pelo ORDBT. Desta forma reduzimos de forma muito
              significativa (nalguns casos totalmente) o CAPEX usualmente
              necessário para a duplicação de sistemas de monitorização;
            </li>
            <li>
              A disponibilização de uma{" "}
              <span className="text-semibold">solução na cloud</span>, numa
              lógica de “As a Service” que integra diferentes camadas de
              algoritmia assente em conceitos de Inteligência Artificial e
              “machine learning” que procura replicar de forma automatizada os
              benefícios de um Gestor de Energia focalizado na análise dos
              perfis de carga de cada utilizador e na procura de oportunidades
              de redução da sua fatura energética;
            </li>
            <li>
              Aquando o lançamento da plataforma de aconselhamento energético e
              para simplificar os canais de comunicação com os participantes,
              será disponibilizado ainda um{" "}
              <span className="text-semibold">chat online</span> que permitirá
              que sejam colocadas dúvidas e questões relativamente a tópicos de
              eficiência energética procurando assim disponibilizar um melhor
              acompanhamento aos participantes da medida.
            </li>
          </ul>
          <p>
            Com esta medida pretende-se assim preparar e disponibilizar
            gratuitamente, durante a duração da presente edição do PPEC, uma
            solução desenvolvida pela digitalmente e USENERGY que implementa o
            conceito de “Gestor Virtual de Energia” a participantes que sejam
            consumidores de energia no setor de “comércio e serviços”.
          </p>
          <p>
            Candidatura PPEC 7.ª Edição Formulário das Medidas Intangíveis 4 em
            conta as reais demonstrações de interesse em participar no projeto
            dos potenciais beneficiários. No âmbito da presente medida a
            plataforma a disponibilizar aos participantes e benificiários tomará
            o nome de “Plataforma FirstFuel”.
          </p>
        </div>
        <div className="desenvolvimento">
          <p className="title">Desenvolvimento da medida por etapas</p>
          <p>
            A medida “FirstFuel” preconizada nesta candidatura será executada
            seguindo 5 Etapas/”Work Packages” que são agora descritas:
          </p>
          <ul>
            <li>
              <span className="text-semibold">WP1 - </span>Definição e execução
              do plano de comunicação Nesta etapa será delineado e executado o
              plano de comunicação que procurará garantir o envolvimento de
              participantes para se atingir o objetivo de envolvimento de 1000
              CPE’s na utilização da plataforma de aconselhamento energético
              prevista a ser disponibilizada. · Importa referir que todo o plano
              de comunicação previsto assentará exclusivamente numa abordagem
              “online”, iniciando com a preparação e disponibilização do website
              para a medida e dando uma ênfase à comunicação através das redes
              sociais para garantir uma elevada eficiência da alocação de
              recursos. De facto, tendo em conta não só a eficiência na alocação
              de recursos mas também a promoção da eficiência energética (em
              todos os seus vetores) no decorrer da medida, todos os “eventos” /
              formações / ”webinars” serão realizadas de forma virtual
              pretendendo assim limitar o impacto em termos de emissões de gases
              de efeito de estufa da execução da própria medida. No âmbito da
              presente etapa serão assim organizadas as diversas sessões de
              esclarecimento e divulgação da medida e que possam funcionar
              também como para a agregação de novos participantes, nomeadamente:
              <ul>
                <li>
                  Será organizada uma sessão inicial de apresentação da medida
                  em regime online;
                </li>
                <li>
                  Serão organizados, 5 “webinars” no decorrer da execução da
                  medida que assentarão na apresentação das funcionalidades da
                  plataforma procurando utilizar exemplos concretos de
                  participantes presentes;
                </li>
                <li>
                  Será ainda organizada uma sessão de encerramento da medida
                  onde se procurará fazer um resumo dos objetivos alcançados e
                  será apresentado o modelo previsto para a continuidade da
                  plataforma “FirstFuel” após o período de execução do PPEC.
                </li>
                <li>
                  Participação em pelo menos dois eventos temáticos sobre
                  energia de modo a promover o conceito.
                </li>
              </ul>
            </li>
            <li>
              <span className="text-semibold">WP2 - </span>Onboarding de
              participantes Nesta etapa será feito o “onboarding” dos
              participantes na medida, recolhendo os consentimentos de partilha
              de dados necessários junto do ORD BT, bem como outros metadados
              que suportarão a criação de um cenário de referência de consumos
              de energia que será validado pelos participantes e servirá também
              para avaliar o impacto gerado pela medida “FirstFuel” ao longo da
              duração do PPEC. Será também preparado e levado a cabo no âmbito
              desta medida um inquérito inicial para se avaliar quais as
              expectativas e funcionalidades mais críticas a disponibilizar,
              adicionalmente ao que está já previsto na plataforma de
              aconselhamento energético.
            </li>
            <li>
              <span className="text-semibold">WP3 - </span>Adaptação e
              Disponibilização da solução aconselhamento energético “FirstFuel”
              Esta será a etapa que irá preparar e ajustar a plataforma de
              aconselhamento energético assente na utilização de conceitos de
              Inteligência Artificial e “machine learning”, tanto em termos de
              imagem (alinhando-a à imagem escolhida para a medida “FirstFuel”),
              tanto em termos de mecanismos de ingestão de dados e a irá colocar
              disponível na cloud para utilização dos participantes da medida.
              Conforme já referido, os participantes da medida terão à sua
              disposição 2 mecanismos para poderem integrar os seus dados de
              consumos de energia na plataforma de aconselhamento energético e
              assim usufruir dos vários módulos de “Data Analytics”
              disponibilizados, nomeadamente:
              <ul>
                <li>
                  Através da integração de dados via o ORD BT em que para isso
                  será necessário que na plataforma do ORD BT seja atribuído
                  pelo participante, o consentimento de partilha de dados
                </li>
              </ul>
            </li>
            <li>
              <span className="text-semibold">WP4 - </span>
              Monitorização e Acompanhamento Com base nos cenários de referência
              estabelecidos para cada participante na etapa WP2, nesta etapa
              serão quantificados os benefícios gerados no decorrer da execução
              da presente medida, e também aqueles benefícios que foram
              identificados como possíveis, mas que os detentores dos CPE’s
              optaram por não implementar.
              <ul>
                <li>
                  Nesta etapa entre os meses 15 e 17 será também executado um
                  levantamento com um subconjunto de participantes da medida
                  “FirstFuel”, das principais melhorias e funcionalidades
                  adicionais a implementar, que alimentará a etapa WP3 no que se
                  refere à tarefa de implementação de “Ajustes e fine-tunning da
                  solução” prevista ocorrer entre os meses 16 e 19.
                </li>
                <li>
                  Ainda nesta etapa e com base nas melhores práticas
                  identificadas para cada tipologia de participantes serão
                  compilados e “outputs” (um vídeo e um documento simplificado)
                  em formato digital para poderem ser mais facilmente
                  partilhados, que irão procurar elencar as medidas mais
                  adequadas e com maior impacto tanto em termos da redução de
                  consumos com na redução dos custos energéticos.
                </li>
              </ul>
            </li>
            <li>
              <span className="text-semibold">WP5 - </span>Gestão e coordenação
              de projeto Na presente etapa serão asseguradas as tarefas de
              gestão corrente do projeto bem como serão também garantidos todos
              os reportes e interações necessárias com a ERSE e com quaisquer
              outros “stakeholders” no projeto, como por exemplo o ORD.
            </li>
          </ul>
        </div>
        <div className="beneficios">
          <p className="title">Benefícios da medida</p>
          <p>
            Identificar os benefícios esperados da medida (por exemplo:
            percentagem esperada do públicoalvo com alteração de comportamento
            decorrente de uma ação de formação). Quantificar, se possível, as
            poupanças de energia esperadas que resultam da medida.
          </p>
          <p>
            A presente medida incorpora benefícios muito tangíveis para os seus
            participantes e que procuramos quantificar em 2 vetores distintos e
            cumulativos:
          </p>
          <ul>
            <li>
              Por um lado, a disponibilização da plataforma “FirstFuel”, que
              comparativamente a outras soluções de monitorização existentes no
              mercado, apresenta funcionalidades muito diferenciadoras em termos
              de análise inteligente dos dados de consumos e não obriga à
              instalação de medidores de energia adicionais que replicam os já
              instalados pelo ORD e se podem quantificar em média e de forma
              conservadora em pelo menos € 150 por medidor.
            </li>
            <li>
              Por outro lado, e considerando consumos médios para a tipologia de
              potenciais participantes que se estimam em cerca de 18.000
              kWh/ano, um potencial de redução de consumos muito conservador em
              torno dos 10% e um custo médio de 0,16 €/kWh (sem IVA) calculamos
              um potencial de benefícios provenientes da redução de custos para
              os 1.000 CPE’s que pretendemos envolver como participantes, de
              aproximadamente € 288.000 por ano. Não obstante temos também de
              ter em consideração que na fase de lançamento da plataforma
              “FirstFuel” muito provavelmente não existirão desde logo os 1.000
              CPE’s participantes (sendo expectável que esse número possa vir a
              ser alcançado durante o 2º ano de execução do PPEC) e também que a
              proposta das medidas de eficiência energética se fará assente na
              análise dos dados de consumos e que a própria adopção se fará por
              parte dos participantes, quando sentirem a confiança necessária.
              Neste sentido assumimos que durante a duração do PPEC será
              possível atingir apenas 50% do potencial estimado, ou seja €
              144.000 de poupanças anuais para os participantes na medida.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sobre;
