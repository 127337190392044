import { IconArrowRight } from "@tabler/icons";
import React from "react";
import { Link } from "react-router-dom";
import heroimg from "../assets/heroimages.png";
import solucaoimg from "../assets/solucao.png";

const Home = () => {
  return (
    <>
      <div className="hero">
        <div className="row">
          <div className="col-md-8 col-lg-5 offset-lg-1 hero-left pt-md-2 pt-lg-0">
            <span className="hero-quote">
              O combustível que não temos de utilizar para satisfazermos as
              nossas necessidades.
            </span>
            <Link to="/sobre" className="primary-btn">
              Saber mais <IconArrowRight size={24} color="#eb595e" stroke={1} />
            </Link>
          </div>
          <div className="col-md-4 col-lg-4 offset-lg-1 hero-img pt-5 mt-5 pt-md-0 mt-md-0">
            <img src={heroimg} alt="" />
          </div>
        </div>
      </div>
      <div className="parallax-image d-none d-md-block"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className="quote-home">
              <span className="quote">
                O principal objetivo é a{" "}
                <span className="color-primary">remoção</span> de barreiras de
                mercado
              </span>
            </div>
            <div className="objetivo-home">
              <div className="row">
                <div className="col-md-12">
                  <p>
                    Isto será possível tendo em vista a promoção da eficiência
                    energética a clientes do setor de “comércio e serviços”
                    através da disponibilização de uma solução de aconselhamento
                    energético assente em conceitos de Inteligência Artificial e
                    “machine learning” e no acesso aos dados de consumos de
                    energia disponibilizados pelos contadores de energia
                    instalados pelo Operador da Rede de Distribuição em Baixa
                    Tensão (ORDBT).
                  </p>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-12">
                      <p>
                        A solução para a execução desta medida é{" "}
                        <span className="text-semibold">disruptiva</span> e{" "}
                        <span className="text-semibold">
                          altamente escalável
                        </span>
                        , face às soluções de monitorização que existem no
                        mercado devido a 3 características principais:
                      </p>
                    </div>
                    <div className="col-md-10 mt-3">
                      <ul className="custom-home-list">
                        <li>Acesso aos dados de consumos de energia</li>
                        <li>Disponibilização de uma solução na cloud</li>
                        <li>Dúvidas e questões em tempo real</li>
                      </ul>
                      <div className="pt-4 d-flex justify-content-start">
                        <Link to="/sobre" className="primary-btn mt-5">
                          Mais informação{" "}
                          <IconArrowRight
                            size={24}
                            color="#eb595e"
                            stroke={1}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-none d-md-flex justify-content-end">
                  <img src={solucaoimg} width="400" alt="" />
                </div>
              </div>
            </div>
            <div className="etapas-home mb-4">
              <p className="subtitle">
                A medida FirstFuel preconizada será executada seguindo 5 etapas
              </p>

              <div className="row mt-5">
                <div className="col-md-4">
                  <p className="text-semibold">
                    WP<span className="color-primary">1</span>
                  </p>
                  <p>Definição e execução do plano de comunicação</p>
                </div>
                <div className="col-md-4">
                  <p className="text-semibold">
                    WP<span className="color-primary">2</span>
                  </p>
                  <p>Onboarding de participantes</p>
                </div>
                <div className="col-md-4">
                  <p className="text-semibold">
                    WP<span className="color-primary">3</span>
                  </p>
                  <p>
                    Adaptação e disponibilização da solução de aconselhamento
                    energético{" "}
                    <b>
                      FirstFuel<span className="color-primary">.</span>
                    </b>
                  </p>
                </div>
                <div className="col-md-4">
                  <p className="text-semibold">
                    WP<span className="color-primary">4</span>
                  </p>
                  <p>Monitorização e acompanhamento</p>
                </div>
                <div className="col-md-4">
                  <p className="text-semibold">
                    WP<span className="color-primary">5</span>
                  </p>
                  <p>Gestão e coordenação de projeto</p>
                </div>
                <div className="col-md-4 d-flex justify-content-end">
                  <Link to="/sobre" className="primary-btn mt-5">
                    Ver detalhes{" "}
                    <IconArrowRight size={24} color="#eb595e" stroke={1} />
                  </Link>
                </div>
              </div>
            </div>

            <div className="quote-home">
              <span className="quote">
                <Link to="/aderir" className="primary-link">
                  Adira já
                </Link>{" "}
                e comece a economizar energia
              </span>
            </div>

            {/* <div className="row">
              <div className="col-md-6 offset-md-3">
                <div className="companies flex-column flex-md-row gap-5">
                  <a
                    href="https://www.erse.pt/inicio/"
                    target="_blank"
                    rel="noreferrer">
                    <img
                      src="https://simulador.potencia.erse.pt/static/share/2022-12/2932bd51-18a3-4c09-ac91-dd5c676af085/61c95db7-8cba-40be-9cf1-b523355f6b3c/logo/cd77123f-8519-4bea-8ad4-2d6ca1d16a40/logo_erse-.png"
                      alt="erse"
                    />
                  </a>
                  <a
                    href="https://www.usenergy.pt"
                    target="_blank"
                    rel="noreferrer">
                    <img
                      src="https://www.usenergy.pt/img/logo.png"
                      alt="usenergy"
                    />
                  </a>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
